import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {PaymentRequestNavigationService} from '../../services/paymentRequest/PaymentRequestNavigationService';
import {PaymentRequestNavigationStoreProps, PaymentRequestState} from '../../../types/paymentRequestApp.types';
import {PaymentRequestService} from '../../services/paymentRequest/PaymentRequestService';
import {PaymentRequestStatus} from '../../models/paymentRequest/PaymentRequest.model';

export class PaymentRequestNavigationStore {
  private readonly navigationService: PaymentRequestNavigationService;
  private readonly paymentRequestService: PaymentRequestService;
  private readonly siteStore: SiteStore;
  private readonly updateComponent: () => void;
  private readonly isSSR: boolean;

  constructor({
    navigationService,
    paymentRequestService,
    siteStore,
    updateComponent,
    isSSR,
  }: {
    navigationService: PaymentRequestNavigationService;
    paymentRequestService: PaymentRequestService;
    siteStore: SiteStore;
    updateComponent: () => void;
    isSSR: boolean;
  }) {
    this.navigationService = navigationService;
    this.paymentRequestService = paymentRequestService;
    this.siteStore = siteStore;
    this.updateComponent = updateComponent;
    this.isSSR = isSSR;
  }

  private readonly navigateToThankYouPage = () => {
    this.navigationService.navigateToThankYouPage();
  };

  public navigateToThankYouPageWhenAlreadyPaid = () => {
    if (
      this.paymentRequestService.paymentRequest.status === PaymentRequestStatus.paid &&
      this.navigationService.paymentRequestState === PaymentRequestState.unpaid
    ) {
      this.navigationService.navigateToThankYouPage();
    }
  };

  public toProps(): PaymentRequestNavigationStoreProps {
    return {
      continueShoppingUrl: this.navigationService.continueShoppingUrl,
      navigateToThankYouPage: this.navigateToThankYouPage,
      isSSR: this.isSSR,
      paymentRequestState: this.navigationService.paymentRequestState,
    };
  }
}
