import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {PaymentRequestStoreProps} from '../../../types/paymentRequestApp.types';
import {PaymentRequestService} from '../../services/paymentRequest/PaymentRequestService';

export type PaymentRequestStoreConfig = {
  flowAPI: ControllerFlowAPI;
  siteStore: SiteStore;
  paymentRequestService: PaymentRequestService;
  updateComponent: () => void;
};

export class PaymentRequestStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly paymentRequestService: PaymentRequestService;
  private readonly updateComponent: () => void;

  constructor({flowAPI, siteStore, paymentRequestService, updateComponent}: PaymentRequestStoreConfig) {
    this.flowAPI = flowAPI;
    this.siteStore = siteStore;
    this.paymentRequestService = paymentRequestService;
    this.updateComponent = updateComponent;
  }

  public toProps(): PaymentRequestStoreProps {
    return {
      paymentRequest: this.paymentRequestService.paymentRequest,
    };
  }
}
