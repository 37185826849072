import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {ILink} from '@wix/wixstores-client-core';
import {getHeadlessUrl, NavigationType} from '@wix/wix-to-headless-redirect-client';
import {PaymentRequestState} from '../../../types/paymentRequestApp.types';
import {THANK_YOU_PAGE_PATH} from '../../../components/PaymentRequest/constants';

export class PaymentRequestNavigationService {
  private readonly siteStore: SiteStore;
  private readonly controllerConfig: IWidgetControllerConfig;
  private continueShoppingLink!: ILink;
  public paymentRequestState: PaymentRequestState = PaymentRequestState.unpaid;

  constructor({siteStore, controllerConfig}: {controllerConfig: IWidgetControllerConfig; siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.controllerConfig = controllerConfig;
  }

  public async load() {
    const status = this.calculatePaymentRequestStatus();
    this.setPaymentRequestState(status);
    this.continueShoppingLink = await this.fetchContinueShoppingLink();
  }

  public setPaymentRequestState(state: PaymentRequestState) {
    this.paymentRequestState = state;
  }

  private calculatePaymentRequestStatus(): PaymentRequestState {
    if (this.siteStore.location.path[2] === THANK_YOU_PAGE_PATH) {
      return PaymentRequestState.paid;
    }

    return PaymentRequestState.unpaid;
  }

  private async fetchContinueShoppingLink(): Promise<ILink> {
    const headlessUrl = getHeadlessUrl({
      query: this.siteStore.location.query,
      baseUrl: this.siteStore.location.baseUrl,
      navParams: {logicalName: NavigationType.ECOM_CONTINUE_BROWSING},
    });

    if (headlessUrl) {
      return this.getExternalLink(headlessUrl);
    }

    return this.siteStore.getHomepageLink();
  }

  public navigateToThankYouPage() {
    const url = new URL(this.siteStore.location.url);
    url.pathname = `${url.pathname}/${THANK_YOU_PAGE_PATH}`;
    this.siteStore.navigateToLink(this.getInternalLink(url.toString()));
  }

  private getExternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_top', url}};
  }

  private getInternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_self', url}};
  }

  public get continueShoppingUrl() {
    return this.continueShoppingLink.url;
  }
}
