import {ambassadorWithHeaders} from '../../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {getOrderPaymentRequest} from '@wix/ambassador-ecom-v1-order-payment-request/http';
import {PaymentRequestModel} from '../../models/paymentRequest/PaymentRequest.model';
import {OrderPaymentRequest} from '@wix/ambassador-ecom-v1-order-payment-request/types';

export class PaymentRequestService {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly orderPaymentRequestId?: string;
  public paymentRequest!: PaymentRequestModel;

  constructor({siteStore, flowAPI}: {flowAPI: ControllerFlowAPI; siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.orderPaymentRequestId = this.siteStore.location.path[1];
  }

  public async init(): Promise<void> {
    await this.fetchPaymentRequest();
  }

  public async fetchPaymentRequest(): Promise<void> {
    if (!this.orderPaymentRequestId) {
      console.error('No orderPaymentRequestId in query params');
      throw new Error('no order payment request id');
    } else {
      try {
        const {data} = await ambassadorWithHeaders(
          getOrderPaymentRequest({
            orderPaymentRequestId: this.orderPaymentRequestId,
          }),
          this.siteStore,
          this.flowAPI
        );

        /* istanbul ignore next */
        if (data.orderPaymentRequest) {
          this.setPaymentRequest(data.orderPaymentRequest);
        } else {
          /* istanbul ignore next */
          console.error('No order payment request data from the API');
          /* istanbul ignore next */
          throw new Error('No order payment request data');
        }
      } catch (error) {
        console.error('handle get and not found errors', error);
      }
    }
  }

  private setPaymentRequest(paymentRequest: OrderPaymentRequest) {
    this.paymentRequest = new PaymentRequestModel(paymentRequest, {
      locale: this.flowAPI.controllerConfig.wixCodeApi.window.locale,
    });
  }
}
