import {OrderPaymentRequest, Status} from '@wix/ambassador-ecom-v1-order-payment-request/types';
import {mapNullToUndefined} from '../../utils/mapperHelper.utils';
import {PaymentRequestPriceModel} from './PaymentRequestPrice.model';

export enum PaymentRequestStatus {
  paid = 'OTHER_DEFAULT',
  unpaid = 'UNPAID',
  expired = 'EXPIRED',
}

export class PaymentRequestModel {
  public id: string;
  public orderId?: string;
  public orderNumber?: string;
  public status: PaymentRequestStatus;
  public total: PaymentRequestPriceModel;
  public currency?: string;
  public paymentGatewayOrderId?: string;
  public title: string;
  public description: string;
  public expirationDate?: string;
  public hasItemsWithLongPrice: boolean;

  constructor(paymentRequest: OrderPaymentRequest, options: {locale: string}) {
    this.id = paymentRequest.id!;
    this.orderId = mapNullToUndefined(paymentRequest.orderId);
    this.orderNumber = mapNullToUndefined(paymentRequest.orderNumber) ?? '';
    this.status = paymentRequest.status ? toPaymentRequestStatus(paymentRequest.status) : PaymentRequestStatus.unpaid;
    this.total = new PaymentRequestPriceModel(paymentRequest.amount);
    this.currency = mapNullToUndefined(paymentRequest.currency);
    this.paymentGatewayOrderId = mapNullToUndefined(paymentRequest.paymentGatewayOrderId);
    this.title = mapNullToUndefined(paymentRequest.title) ?? '';
    this.description = mapNullToUndefined(paymentRequest.description) ?? '';
    this.expirationDate = paymentRequest.expirationDate
      ? new Intl.DateTimeFormat(options.locale, {day: '2-digit', month: 'short', year: 'numeric'}).format(
          paymentRequest.expirationDate
        )
      : undefined;
    this.hasItemsWithLongPrice = this.total.formattedAmount.length > 12;
  }
}

const toPaymentRequestStatus = (status: Status) => {
  switch (status) {
    case Status.PAID:
      return PaymentRequestStatus.paid;
    case Status.EXPIRED:
      return PaymentRequestStatus.expired;
    case Status.UNPAID:
    case Status.UNKNOWN_STATUS:
    default:
      return PaymentRequestStatus.unpaid;
  }
};
